<template>
  <div class="flex items-center">
    <input
      :id="id"
      v-model="isChecked"
      type="checkbox"
      class="styled-checkbox mr-1"
      :class="checkboxClass"
      :name="name"
      :disabled="disabled"
      :checked="checked"
    >
    <slot name="label">
      <label
        :for="id"
        class="text-labels-yellow"
      >{{ label }}</label>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',

  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    checkboxClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isChecked: this.checked,
    }
  },
  watch: {
    isChecked (value) {
      this.$emit('checkbox-changed', value)

      if (value) {
        this.$emit('enabled');

        return;
      }

      this.$emit('disabled');
    }
  }
};
</script>

<style lang="scss" scoped>
.styled-checkbox {
    @apply absolute opacity-0;

    & + label {
        @apply relative cursor-pointer p-0
    }

    & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: transparent;
        border-radius: 0.25rem;
        border-width: 1px;
        border-color: #FFDD00;

    }

    &.is-circle {
        & + label:before {
            border-radius: 9999px;

        }
    }

    &.is-brand {
        & + label:before {
            border-color: var(--label-yellow);
        }

        &:checked + label:before {
            background: var(--label-yellow);
        }
    }

    &.is-blue {
        & + label:before {
            border-color: var(--primary-5);
        }

        &:checked + label:before {
            background: var(--primary-4);
        }
    }

    &.is-white {
        & + label:before {
            border-color: var(--white);
        }

        &:checked + label:before {
            background: var(--white);
        }
    }

    // Box focus
    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
        background: #FFDD00;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #fff;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 10px;
        background: var(--primary-5);
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 #002238,
        4px 0 0 #002238,
        4px -2px 0 #002238,
        4px -4px 0 #002238,
        4px -6px 0 #002238,
        4px -8px 0 #002238;
        transform: rotate(45deg);

    }

    &.is-brand {
        &:checked + label:after {
            background: #fff;
            box-shadow: 2px 0 0 #fff,
            4px 0 0 #fff,
            4px -2px 0 #fff,
            4px -4px 0 #fff,
            4px -6px 0 #fff,
            4px -8px 0 #fff;

        }
    }

    &:checked + label:after {
        background: #fff;
        box-shadow: 2px 0 0 #fff,
        4px 0 0 #fff,
        4px -2px 0 #fff,
        4px -4px 0 #fff,
        4px -6px 0 #fff,
        4px -8px 0 #fff;

    }
}
</style>